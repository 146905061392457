<template>
    <v-menu
        dense
        :offset-x="isOffsetX"
        :offset-y="isOffsetY"
        :open-on-hover="isOpenOnHover"
    >
        <template v-slot:activator="{ on }">
            <v-list-item
                dense
                v-if="icon"
                :color="color"
                v-on="on"
                @click="emitClickEvent(name)"
                style="background: #1e1e1e"
            >
                <v-list-item-title dense
                    >{{ name }}<v-icon>{{ icon }}</v-icon></v-list-item-title
                >
            </v-list-item>
            <v-list-item dense v-else :color="color" v-on="on">
                <v-list-item-title dense>{{ name }}</v-list-item-title>
            </v-list-item>
        </template>
        <v-list dense style="max-height: 70vh">
            <template v-for="(item, index) in menuItems">
                <v-divider v-if="1 == 2" :key="index" />
                <DanMenu
                    v-else-if="item.children"
                    :key="item.id"
                    :name="item.book_name"
                    :menu-items="item.children"
                    @dan-menu-click="emitClickEvent"
                    :is-open-on-hover="true"
                    :icon="'mdi-menu-right'"
                    :is-offset-x="true"
                    :is-offset-y="false"
                    :is-sub-menu="true"
                />
                <v-list-item
                    dense
                    v-else
                    :key="item.book_name"
                    @click="emitClickEvent(item.book_name)"
                    style="background: #1e1e1e"
                >
                    <v-list-item-title dense>{{
                        item.book_name
                    }}</v-list-item-title>
                </v-list-item>
            </template>
        </v-list>
    </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "DanMenu",
    props: {
        name: String,
        icon: String,
        menuItems: Array,
        color: { type: String, default: "primary" },
        isOffsetX: { type: Boolean, default: false },
        isOffsetY: { type: Boolean, default: true },
        isOpenOnHover: { type: Boolean, default: false },
        isSubMenu: { type: Boolean, default: false },
        transition: { type: String, default: "scale-transition" },
    },
    methods: {
        emitClickEvent(item) {
            // this.closeAllMenus() // Theoretically, create a method that does this as a workaround
            this.$emit("dan-menu-click", item);
        },
    },
    mounted() {
        // console.log(this.menuItems);
    },
};
</script>
<style scoped></style>
