<template>
    <v-card class="d-flex flex-column" outlined>
        <v-card-title style="border-bottom: 1px solid #404040">
            <div
                class="text-left"
                style="
                    font-size: 13px;
                    font-weight: normal;
                    display: inline-block;
                "
            >
                CCY EXPOSURE Top:
            </div>
            <v-select
                :items="topSelection"
                dense
                class="pl-2 pb-2"
                offset-y
                hide-details
                style="
                    display: block;
                    float: right;
                    font-size: 13px;
                    max-width: 60px;
                "
                v-model="topNumber"
            >
            </v-select>
        </v-card-title>
        <div class="flex-grow-1" style="min-height: 50px">
            <div id="exposureChart"></div>
        </div>
    </v-card>
</template>
<script>
import * as echarts from "echarts";
import { mapState } from "vuex";

export default {
    props: {
        chartHeight: Number,
        exposureData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            topSelection: [10, 20],
            topNumber: 10,
            noDataMessage: "No Data Available",
            chart: Object,
            // data: [],
            apiResult: [],
            broker: "",
            option: {
                title: {
                    text: "",
                    left: "center",
                    top: "center",
                    textStyle: { color: "#fff" },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        // 坐标轴指示器，坐标轴触发有效
                        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                grid: {
                    left: 20,
                    right: 20,
                    top: 20,
                    bottom: 20,
                    containLabel: true,
                },
                xAxis: {
                    type: "value",
                    position: "top",
                    axisLabel: {
                        show: true,
                        color: "#fff",
                        formatter: (data) => {
                            return data;
                        },
                    },
                    splitLine: { lineStyle: { type: "dashed" }, show: false },
                    axisLabel: {
                        color: "#fff",
                        formatter: function (value, index) {
                            let data = value;

                            if (Math.abs(value) > 1000000) {
                                data = (value / 1000000).toFixed(0) + "M";
                                return data;
                            } else if (Math.abs(value) >= 1000) {
                                data = (value / 1000).toFixed(0) + "K";
                                return data;
                            } else if (Math.abs(value) < 1000) {
                                data = value;
                                return data;
                            }
                            return value;
                        },
                    },
                },
                yAxis: {
                    type: "category",
                    axisLine: { show: false },
                    axisLabel: { show: true, color: "#fff", interval: 0 },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    data: [],
                },
                series: [
                    {
                        name: "Exposure",
                        type: "bar",
                        stack: "Total",
                        label: {
                            show: true,
                            formatter: function (a, b, c) {
                                const result = [];
                                let counter = 0;
                                // console.log(a, " | ", b, " | ", c);
                                const numArray = a.data.toString().split(".");
                                const num = numArray[0];
                                let newStr = "";

                                if (Math.abs(a.data) > 1000000) {
                                    return (
                                        (a.data / 1000000)
                                            .toFixed(2)
                                            .toString() + " M"
                                    );
                                } else if (Math.abs(a.data) > 100000) {
                                    return (
                                        (a.data / 1000).toFixed(2).toString() +
                                        " K"
                                    );
                                } else if (Math.abs(a.data) > 10000) {
                                    return (
                                        (a.data / 1000).toFixed(2).toString() +
                                        " K"
                                    );
                                }

                                for (let i = num.length - 1; i >= 0; i--) {
                                    counter++;
                                    result.unshift(num[i]);
                                    if (!(counter % 3) && i != 0) {
                                        result.unshift(",");
                                    }
                                }

                                if (numArray.length > 1) {
                                    newStr = result.join("");
                                    newStr += "." + numArray[1];
                                    return newStr;
                                } else {
                                    return result.join("");
                                }
                            },
                            // formatter: function(value, index) {
                            //     let value;
                            //     if (Math.abs(value) >= 1000) {
                            //         value = (value/1000).toFixed(0) + 'k';
                            //     } else if (Math.abs(value) < 1000) {
                            //         value = value;
                            //     }
                            //     return value
                            // },
                            color: "#fff",
                        },
                        itemStyle: {
                            color: (data) => {
                                if (data.value > 0) return "#2c69ab";
                                if (data.value < 0) return "#c82c20";
                            },
                        },
                        data: [],
                    },
                ],
            },
        };
    },
    computed: {
        ...mapState("Home", [""]),
    },
    methods: {
        /**
         * Sort object
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        sortObject(data) {
            return Object.entries(data).sort(
                (a, b) => Math.abs(b[1]) - Math.abs(a[1])
            );
        },
        resortObject(data) {
            const temp = data.sort((a, b) => b[1] - a[1]);
            return temp;
        },
        /**
         * Dealing up coming exposure data, generate x & y data
         * @return  {[type]}  [return description]
         */
        processData(data) {
            // sort exposure data
            let exposureData = this.sortObject(data);
            if (this.topNumber !== "All") {
                exposureData = exposureData.splice(0, this.topNumber);
            }

            exposureData = this.resortObject(exposureData);

            const xValues = [];
            const yValues = [];
            let count = 0;

            for (let i = 0; i < exposureData.length; i++) {
                if (count === this.topnumber) break;
                const item = exposureData[i];
                xValues.push(item[0]);
                yValues.push(item[1].toFixed(2));
                count += 1;
            }
            this.option.yAxis.data = xValues;
            // const newHeight = 90 + this.option.yAxis.data.length * 27;
            // this.domHeight = newHeight > 400 ? 400 : newHeight;
            this.option.series[0].data = yValues;
            this.chart.setOption(this.option);
            this.chart.resize({ height: this.chartHeight });
        },
    },
    watch: {
        /**
         * Watch exposure data changes
         * @param   {[type]}  nv  [new value]
         * @return  {[type]}      [return description]
         */
        exposureData(nv) {
            this.processData(nv);
        },
        topNumber() {
            this.processData(this.exposureData);
        },
    },
    mounted() {
        this.chart = echarts.init(document.getElementById("exposureChart"));
    },
};
</script>

<style scoped>
#exposureChart {
    height: 100%;
}
</style>
